import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";
import 'babel-polyfill'

export default class TeamPage extends React.Component {
    componentDidMount() {
        if (typeof (window) !== "undefined") {
            let image = Array.from(document.getElementsByTagName('img'));
            image.forEach(images => images.title = images.alt);
        }
    }

    render() {
        let siteType = process.env.GATSBY_API_URL;
        let teamData;
        if (siteType === "prod") {
            teamData = this.props.data.allStrapiTeams.edges.filter(edges => edges.node.status === "published");
            teamData.sort((a, b) => a.node.order - b.node.order);
        } else if (siteType === "dev") {
            teamData = this.props.data.allStrapiTeams.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
            teamData.sort((a, b) => a.node.order - b.node.order);
        }
        let offersData = this.props.data.allStrapiOfferPages.edges;
        return (
            <Layout page="team">
                <Helmet title='Das Chatbot-Expertenteam | Paixon'>
                    <meta charSet="utf-8"/>
                    <meta name="description"
                          content="Wir sind ein eingespieltes und erfahrenes Chat- und Voicebot-Expertenteam. Unsere Leidenschaft gehört der modernen und agilen Softwareentwicklung."/>
                </Helmet>
                <Header className="team" slogan={this.props.data.allStrapiTeamPages.edges[0].node.slogan}
                        blogphoto={{backgroundImage: `url(${this.props.data.allStrapiTeamPages.edges[0].node.Photo.publicURL})`}}
                        offersData={offersData}/>
                <main className="pt container team">
                    <ReactMarkdown children={this.props.data.allStrapiTeamPages.edges[0].node.content}/>

                    <div className="teamList">
                        {teamData.map(member => {
                            return (
                                <div className="teamMember-card">
                                    <div className="teamMember">
                                        <a href={`/team/${member.node.url}`}>
                                            <img src={member.node.portraitPhoto.publicURL}
                                                 alt={`Foto ${member.node.name}`}/>
                                            <div className="authorinfo">
                                                <h3>{member.node.name}</h3>
                                                <hr/>
                                                <p>{member.node.role}</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="teamMember-card">
                            <div className="jobsOffers">
                                <a href={`/jobs`}>
                                    <div className="joboffer">
                                        <div className="offerText">
                                            <h2>Werde ein Teil</h2>
                                            <h2>unseres Teams</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        )
    }

}


export const pageQuery = graphql`
    query TeamsQuery
        {
            allStrapiTeams
            {
                edges
                {
                    node
                    {
                        id
                        name
                        email
                        status
                        degree
                        characteristics
                        portraitPhoto
                        {
                            publicURL
                        }
                        description
                        linkedin
                        phone
                        order
                        role
                        url
                    }
                }
            }
            allStrapiTeamPages
            {
                edges
                {
                    node
                    {
                        content
                        Photo
                        {
                            publicURL
                        }
                        slogan
                    }
                }
            }
            allStrapiOfferPages
            {
                edges
                {
                    node
                    {
                        title
                        url
                        order
                    }
                }
            }
        }
    `
